.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6rem;
    height: 20rem;
}

.social_media {
    gap: 4rem;
    display: flex;
    justify-content: center;
}

.social_media>a>img {
    width: 2rem;
    cursor: pointer;
}

.logo_section>a>img {
    width: 10rem;
}

.blur-footer-1 {
    width: 32rem;
    height: 22rem;
    left: 10rem;
}

.blur-footer-2 {
    width: 25rem;
    height: 16rem;
    right: 10rem;
    background: red;
    filter: blur(200px);
}