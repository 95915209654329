.plans-container {
    position: relative;
}

.Plans-header {
    display: flex;
    gap: 2rem;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3rem;
    font-style: italic;
    color: white;
    position: relative;
    margin-bottom: 3rem;
}

.Plans-cards {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    color: white;
    justify-content: center;
}

.card {
    background-color: var(--caloryCard);
    color: whites;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 15rem;
    padding: 1.5rem;
}

.card:nth-child(2) {
    background: var(--planCard);
    transform: scale(1.1);

}

.card>svg {
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}

.card>:nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
}

.card>:nth-child(3) {
    font-size: 3rem;
    font-weight: bold;
}

.card>:nth-child(5) {
    font-size: 0.8rem;
}

.features {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature {
    display: flex;
    align-items: center;
    gap: 1rem;
    z-index: 1;
}

.feature>img {
    width: 1rem;
}

.card:nth-child(2)>svg {
    fill: white;
}

.card:nth-child(2)>button {
    color: var(--orange);
}

.blur-plans-1 {
    width: 32rem;
    height: 23rem;
    left: 1rem;
    top: 6rem;
}

.blur-plans-2 {
    width: 32rem;
    height: 23rem;
    right: 0rem;
    top: 10rem;
}

@media screen and (max-width:768px) {
    .Plans-header {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: xx-large;
    }

    .Plans-cards {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: small;
    }

    .card {
        font-size: small;
    }

    .card:nth-child(2) {
        transform: none;
    }
}