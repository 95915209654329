.Testimonials {
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}

.left-t {
    flex: 1.2 1;
}

.testimonials-header {
    color: var(--orange);
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 2rem;
}

.testimonials {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    color: white;
    font-weight: bold;
    font-size: 3rem;
    gap: 2rem;
}

.testimonials>span:nth-child(3),
.testimonials>span:nth-child(4) {
    font-size: 1rem;
    font-weight: normal;
    text-transform: none;
    line-height: 40px;
    letter-spacing: 2px;
}

.right-t {
    flex: 1 1;
    text-transform: uppercase;
    display: grid;
    gap: 1rem;
    position: relative;
}

.right-t>:first-child {
    border: 2px solid orange;
    height: 300px;
    width: 250px;
    position: absolute;
    right: 11rem;
}

.right-t>:nth-child(2) {
    background: var(--planCard);
    height: 300px;
    width: 256px;
    position: absolute;
    top: 3rem;
    right: 9rem;
}

.right-t>:nth-child(3) {
    width: 16rem;
    height: 20rem;
    object-fit: cover;
    position: absolute;
    right: 10rem;
    top: 1rem;
}

.arrows {
    cursor: pointer;
    display: flex;
    gap: 1rem;
    height: 20px;
    position: absolute;
    bottom: 3rem;
}

@media screen and (max-width:768px) {
    .Testimonials {
        flex-direction: column;
    }

    .left-t>:nth-child(2),
    .left-t>:nth-child(3) {
        font-size: xx-large;
    }

    .right-t {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }

    .right-t>div {
        position: relative;
        display: none;
    }

    .right-t>img {
        display: none;
    }

    .right-t>:last-child {
        display: flex;
        height: 2rem;
        gap: 1rem;
        bottom: 0;
        left: 0;
    }

    .arrows>:first-child {
        height: 2rem;
    }
}