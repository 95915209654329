.Join {
    display: flex;
    gap: 5rem;
    padding: 0 2rem;
}

.left-j {
    flex: 1.1 1;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3rem;
    color: white;
}

.left-j>:first-child {
    border: 2px solid var(--orange);
    border-radius: 20%;
    width: 10.5rem;
}


.left-j>div {
    display: flex;
    gap: 1rem;
}

.right-j {
    flex: 1 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.email-container {
    display: flex;
    flex-direction: row;
    background-color: white;
    width: fit-content;
    padding: 1rem 2rem;
    gap: 3rem;
}

.email-container>input {
    border: none;
    color: gray;
    outline: none;
}

.btn-j {
    background-color: var(--orange);
    color: #fff;
}

@media screen and (max-width:768px) {
    .Join {
        margin-bottom: 2rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .left-j>:first-child {
        margin-bottom: 2rem;
    }

    .left-j>div {
        flex-direction: column;
        font-size: xx-large;
        align-items: center;
    }

    .right-j>form {
        display: flex;
        gap: 2rem;
        flex-direction: column;
        width: 220px;
        height: 20px;
    }
}